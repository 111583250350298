import React from 'react'
import './assets/css/error.scss'


const notFoundPage = (props) => (

    <div className="NotFoundPage">
        <h1>404</h1>
        <h2>Stranica nije pronađena.</h2>
    </div>

);


export default notFoundPage
