import firebase from 'firebase/app'
import 'firebase/storage'

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyBATk24dY3GyrIIyFTrH06QXXFOvGNiVno",
    authDomain: "desk-1322e.firebaseapp.com",
    databaseURL: "https://desk-1322e.firebaseio.com",
    projectId: "desk-1322e",
    storageBucket: "desk-1322e.appspot.com",
    messagingSenderId: "394063003790",
    appId: "1:394063003790:web:5576743c249a6ef0e2c9df",
    measurementId: "G-W4DJ8R1NYZ"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  const storage = firebase.storage();

  export {
      storage, firebase as default
  }