import React from 'react'


const success = (props) => (

    <div className="Success">
        <img src="http://desk.udrugapravoja.hr//assets/correct.png" />
        <h3>Povratak na stranicu?</h3>
        <a href="http://udrugapravoja.hr">UHB RH</a>
    </div>
);

export default success;